<template>
  <v-select
    v-model="visibility"
    :items="visibilityValues"
    item-value="value"
    item-text="name"
    :label="$t('folders.visibility', locale)"
    @change="handleChangeVisibility"
  />
</template>
<script>
  export default {
    props: {
      locale: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        visibility: null,
        visibilityValues: [ 
          {
            value: 'privateByUser',
            name: 'privateByUser',
          },
          {
            value: 'privateAllUser',
            name: 'privateAllUser',
          },
          {
            value: 'public',
            name: 'public',
          },
        ],
      }
    },
    watch: {
      locale () {
        this.translateVisibility()
      },
      value () {
        this.visibility = this.value
      }
    },
    mounted () {
      this.translateVisibility()
      this.visibility = this.value
    },
    methods: {
      translateVisibility () {
        this.visibilityValues = this.visibilityValues.map(item => {
          item.name = this.$t(`folders.${item.value}`, this.locale)
          return item
        })
      },
      handleChangeVisibility () {
        this.$emit('change', this.visibility)
      },
    },
  }
</script>
